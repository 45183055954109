import { useState } from "react";
import { ApplicantInfoOverview } from "../../../../components/Applicant/ApplicantInfoOverview";
import { OverviewHeader } from "../../../../components/Header/OverviewHeader";
import { OverviewWrapper, ProcessWrapper, RowsWrapper } from "../../styles";
import { Modals, ModalTypes } from "../modals";
import { OfferRow } from "./rows/offerRow";
import { ApplicantRow } from "./rows/applicantRow";
import { ApplicationtRow } from "./rows/applicationRow";
import { FinancialRow } from "./rows/financialRow";
import { SignicatRow } from "./rows/signicatRow";
import type { CommonProcess } from "../../../../types/common";
import { ExistingLoanDetailsRow } from "./rows/existingLoanDetailsRow";

const LoanOverviewSe = (flow: CommonProcess) => {
  const { data, flowId } = flow?.caseData ?? {};
  const { country, agentApiApplication: agentApi, } = data || {};
  const { applicant, coapplicant } = agentApi || {};
  const hasQrData = !!data?.integrations?.signicat?.auth?.raw?.signicat?.headless?.idpData?.qrData || "";
  const status = flow?.caseData?.status;
  const isTopUp = data.integrations.leabank.ownExperience?.isTopUp
  // const isTopUp = true
  const [selectedModal, setSelectedModal] = useState<string>("");

  function handleModalClose() {
    setSelectedModal("");
  }

  return (
    <OverviewWrapper>
      <OverviewHeader country={country} label="Loan application" />
      <ProcessWrapper>

        <ApplicantInfoOverview applicant={applicant} coapplicant={coapplicant} />

        <RowsWrapper className="general information">
          <div className="left-side" style={{
            marginRight: "10px",
          }}>
            <ApplicantRow data={data} />
          </div>
          <div className="right-side" style={{
            marginLeft: "10px",
          }}>
            <ApplicationtRow data={data} flowId={flowId} handleModalClick={() => setSelectedModal(ModalTypes.KYC)} />
          </div>
        </RowsWrapper>

        <FinancialRow data={data} />
        <OfferRow data={data} status={status} />
        {isTopUp && (<ExistingLoanDetailsRow data={data} />)}
        {hasQrData && (<SignicatRow data={data} />)}

        <Modals flow={flow} modalToShow={selectedModal} handleModalClose={handleModalClose} />
      </ProcessWrapper>
    </OverviewWrapper >

  );
}

export default LoanOverviewSe;