import { ApplicantCollection } from "../../../components/Applicant/ApplicantCollection"
import { RadioButtonGroup } from "../../../components/Decision/RadioButtonGroup"
import { FormTextArea } from "../../../components/Input/FormTextArea"
import { InfoRow } from "../../../components/InfoRow/InfoRow"
import { LabelBold, ApplicantDetailsWrapper, ApplicantDetailsLabel } from "../manual-check-final/styles"
import { FlexAlignCenter } from "../manual-id-review/style"
import * as Styles from "../styles"
import { Icons } from "@stacc/flow-ui-components"
import type { CommonData } from "../../../types/common"

interface FormConfigProps {
  errorMessages: string | null;
  applicant: any;
  coApplicant: any;
  decision: any;
  handleDecisionChange: (value: any) => void;
  handleNoteChange: (value: any) => void;
  comment: string;
  data: CommonData
  status: string;
}

export const formConfig = ({ errorMessages, applicant, coApplicant, decision, handleDecisionChange, handleNoteChange,
  comment, data, status }: FormConfigProps) => {

  const offerGivenIcon = data?.offer?.finalLoanAmount ? (
    <Icons.SuccessCircle />
  ) : status === "active" ? (
    <Icons.ActiveCircle />
  ) : (
    <Icons.AbortedCircle />
  );

  const applicationDetailsOptions = [
    { label: "Final loan amount", value: data?.offer?.finalLoanAmount },
    { label: "Interest rate", value: data?.offer?.interestRate },
    { label: "Maximum loan amount", value: data?.offer?.maxLoanAmount },
    { label: "Minimum loan amount", value: data?.offer?.minLoanAmount },
    { label: "Startup fee", value: data?.offer?.startupFee },
    { label: "Term fee", value: data?.offer?.termFee },
    { label: "Terms", value: data?.offer?.terms },
    { label: "Effective interest rate", value: data?.offer?.effectiveInterestRate },
    { label: "Monthly cost", value: data?.offer?.monthlyCost },
    { label: "Offer given", value: offerGivenIcon },
  ];

  return [
    {
      // Applicant details
      component: (
        <>
          <ApplicantDetailsWrapper>
            <ApplicantDetailsLabel>Applicant details</ApplicantDetailsLabel>
            <ApplicantCollection {...applicant} />
            {coApplicant && <ApplicantCollection {...coApplicant} />}
          </ApplicantDetailsWrapper>
        </>),
      order: 1,
    },
    // Address details
    {
      component: (
        <div style={{
          padding: "0 28px"
        }}>
          <InfoRow value={data?.integrations?.uc?.addressInformation?.streetName || ''} title="Address" hasMatch={false} hasPadding={false} />
          <InfoRow value={data?.integrations?.LeaBankWeb?.customerInformation?.accountNumber ?? data?.agentApiApplication?.application?.bankAccountNumber ?? ''} title="Account number" hasMatch={false} hasPadding={false} />
        </div>
      ),
      order: 2
    },
    // Application details
    {
      component: (
        <>
          <ApplicantDetailsLabel>Application details</ApplicantDetailsLabel>
          <div style={{
            padding: "0 28px"
          }}>
            {applicationDetailsOptions.map((option, index) => (
              <InfoRow value={option.value} title={option.label} key={`infoRow${option.label}`} hasMatch={false} hasPadding={false} />
            ))}
          </div>
        </>
      ),
      order: 3
    },
    // Decision
    {
      component: (
        <RadioButtonGroup
          title="Decision"
          id="approved"
          radioButtonValue={decision}
          options={[
            { label: "Approve application", value: true },
            { label: "Reject application", value: false },
          ]}
          onChange={(e: any) => handleDecisionChange(e)}
        />
      ),
      order: 4
    },
    // Internal comment
    {
      component: (
        <>
          <FormTextArea
            label="Internal comment"
            name="internal-comment"
            onChange={(e: any) => handleNoteChange(e)}
            defaultValue={comment}
            placeholder="Other applicant information..."
          />
          {errorMessages &&
            <Styles.ErrorMessageBox>
              <FlexAlignCenter>
                <LabelBold>{errorMessages}</LabelBold>
              </FlexAlignCenter>
            </Styles.ErrorMessageBox>
          }
        </>
      ),
      order: 5,
    }

  ]
}